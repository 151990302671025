/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
const GTM_CODE = 'GTM-WW8S64P';
const createGTMNoscript = () => {
  const noscript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${GTM_CODE}`;
  iframe.height = 0;
  iframe.width = 0;
  iframe.style = 'display:none;visibility:hidden';
  noscript.append(iframe);
  document.body.prepend(noscript);
};

export const onRouteUpdate = (state) => {
  createGTMNoscript();
};
