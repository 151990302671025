// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-js": () => import("./../src/pages/auth.js" /* webpackChunkName: "component---src-pages-auth-js" */),
  "component---src-pages-documents-js": () => import("./../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-templates-basic-page-node-js": () => import("./../src/templates/basic-page-node.js" /* webpackChunkName: "component---src-templates-basic-page-node-js" */),
  "component---src-templates-document-node-js": () => import("./../src/templates/document-node.js" /* webpackChunkName: "component---src-templates-document-node-js" */),
  "component---src-templates-event-node-js": () => import("./../src/templates/event-node.js" /* webpackChunkName: "component---src-templates-event-node-js" */),
  "component---src-templates-news-node-js": () => import("./../src/templates/news-node.js" /* webpackChunkName: "component---src-templates-news-node-js" */),
  "component---src-templates-place-node-js": () => import("./../src/templates/place-node.js" /* webpackChunkName: "component---src-templates-place-node-js" */)
}

